<template>
  <el-collapse>
    <el-collapse-item :title="$t('footer.disclosure_1')">
      <div>
        <p>{{ $t('footer.disclosure_html_3') }} <a :href="$t('footer.disclosure_url_2')">{{ $t('footer.disclosure_html_4') }}</a> {{ $t('footer.disclosure_html_5') }}.</p>
      </div>
    </el-collapse-item>
    <el-collapse-item :title="$t('footer.disclosure_2')">
      <div>
        <p>{{ $t('footer.disclosure_html_1') }} <a :href="$t('footer.disclosure_url_1')">{{ $t('footer.disclosure_html_2') }}</a>.</p>
      </div>
    </el-collapse-item>
  </el-collapse>
  <footer>
    <div class="logo">
      <a href="http://www.afdb.org">
        <span class="sr-only">{{ $t('nav.afdb') }}</span>
        <img src="@/assets/afdb-white.svg" alt="MapAfrica logo">
      </a>
    </div>
    <div class="link-container">
      <ul>
        <li>
          <a href="https://www.afdb.org/en/terms-and-conditions/">{{ $t('footer.terms') }}</a>
        </li>
        <li>
          <a :href="locale === 'en' ? 'https://www.afdb.org/en/disclosure-and-access-information/' : 'https://www.afdb.org/fr/politique-de-diffusion-et-dacces-linformation-dai'">{{ $t('footer.disclosure_1') }}</a>
        </li>
        <li>
          <a href="https://www.afdb.org/en/sitemap/">{{ $t('footer.sitemap') }}</a>
        </li>
        <li>
          <a href="https://www.afdb.org/en/glossary/">{{ $t('footer.glossary') }}</a>
        </li>
        <li>
          <a href="https://www.afdb.org/en/rss-feeds/">{{ $t('footer.rss') }}</a>
        </li>
      </ul>
      <p>© {{ new Date().getFullYear()}} <a href="http://www.afdb.org">{{ $t('footer.afdb') }}</a></p>
    </div>
  </footer>
</template>

<script setup>
import { useI18n } from 'vue-i18n'
const { locale } = useI18n()
</script>

<style lang="scss" scoped>
  footer {
    align-items: center;
    background-color: #485566;
    color: #ffffff;
    display: flex;
    flex-direction: row;
    font-size: 0.8rem;
    height: 4rem;
    justify-content: center;
    margin: 0;
    overflow-x: hidden;
    padding: 0;
    position: relative;
    width: 100%;

    div.logo {
      padding: 0.3rem 1rem 0 0;

      a {
        display: block;
        padding: 0 0 0.3rem 1rem;
        text-decoration: none;
        height: 100%;

        img {
          width: 75px;
        }
      }
    }

    .link-container {
      ul {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
      }

      li {
        list-style: disc outside;
        margin: 0 1rem 0 0;
        padding-left: 0;

        &:first-of-type {
          margin: 0 1rem 0 -8px;
          list-style: none;
        }
      }

      a {
        margin: 0 0.5rem;
      }
    }
  }

  @media screen and (max-width: 850px) {
    footer {
      height: 100px;

      .link-container {
        ul {
          li {
            margin: 0 0.5rem 0 0;
            text-indent: -8px;
            padding: 0;
            list-style: none outside;

            &:first-of-type {
              margin: 0 0.5rem 0 0;
              padding: 0;
            }
          }
        }
      }
    }
  }
</style>

<style lang="scss">
.el-collapse-item {
  .el-collapse-item__header {
    padding: 0 1.2rem 0;
  }

  .el-collapse-item__content {
    max-width: 100ch;
    margin: 0 auto;

    a {
  color: #0c7cac;
  text-decoration: none;

    &:hover, &:focus {
      text-decoration: underline;
    }
  }

    p ~ p {
      margin: 1rem 0 0 0;
    }
  }
}
</style>
